*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  /* This is now used to calculate rem , now 1rem=10px. Otherwise it would be 1rem=16px. 16px is the default text value of the html page.  */
  font-size: 62.5%;

  ::-webkit-scrollbar {
    background-color: #ccc;
    width: 1.5rem;
    height: 10%;
    /* margin: 1rem 0; */
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--fourth-blue);
    border-radius: 1rem;
  }
  
}

body {
  box-sizing: border-box;
  font-family: var(--primary-font);
  padding: 0;


}
#file{
  display: none;
}
#file1{
  display: none;
}