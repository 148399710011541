@font-face{
    font-family: 'Aileron-regular';
    src: url('./../public/fonts/aileron/Aileron-Regular.otf');
    font-family: 'Cy Grotesk Wide Demi';
    src: url('./../public/fonts/grotesk/grotesk/Cy\ Grotesk\ Wide\ Demi.ttf');
}

/* font-family: 'Montserrat', sans-serif;
font-family: 'Poppins', sans-serif; */

:root {
    --main-blue: #023e72;
    --main-blue-shade: #023e72dd;
    --secondary-blue: #529ffc;
    --ternary-blue: #ebeff4;
    --fourth-blue:#275A8F;
    --gold: #ddc367;
    --white: #ffffff;
    --green: #023e27;
    --primary-font-color: #333;
    --primary-font:'Poppins', sans-serif;
    /* --primary-font:'Aileron-regular', sans-serif; */
    --secondary-font:'Cy Grotesk Wide Demi', sans-serif;
    --dashboard-font:'Poppins', sans-serif;

    --form-input-text-color:#444;
    --form-password-icon-color:#999;
  }